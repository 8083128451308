.p-progressbar{
    background-color: var(--secondary-background-color);
    border-radius: 24px;
    height: 12px;
}

.p-progressbar-determinate .p-progressbar-value-animate{
    border-radius: 24px;
    background-color: var(--primary-color);
}


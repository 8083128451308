.p-menu.p-menu-overlay {
    background: var(--secondary-background-color);
    border: 1px solid var(--secondary-background-color);
    position: absolute;
}

.p-menu .p-menuitem-content {
    .p-menuitem-link {
        .p-menuitem-text, 
        .p-menuitem-icon {
            color: var(--secondary-color);
            margin: 0px 4px;
        }
    }
}

.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    background-color: var(--secondary-background-color);

    .p-menuitem-link .p-menuitem-text,
    .p-menuitem-link .p-menuitem-icon {
        color: var(--primary-color);
    }
}
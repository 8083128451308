@font-face {
  font-family: "GESSTextMedium";
  src: url("/assets/fonts/gess/GESSTextMedium.eot");
  src: url("/assets/fonts/gess/GESSTextMedium.eot") format("embedded-opentype"),
    url("/assets/fonts/gess/GESSTextMedium.woff2") format("woff2"),
    url("/assets/fonts/gess/GESSTextMedium.woff") format("woff"),
    url("/assets/fonts/gess/GESSTextMedium.ttf") format("truetype"),
    url("/assets/fonts/gess/GESSTextMedium.svg#GESSTextMedium") format("svg");
}

@font-face {
  font-family: "ArbFONTSGESSTextBold_26";
  src: url("/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.eot");
  src: url("/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.eot") format("embedded-opentype"),
    url("/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.woff2") format("woff2"),
    url("/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.woff") format("woff"),
    url("/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.ttf") format("truetype"),
    url("/assets/fonts/gessbold/ArbFONTSGESSTextBold_26.svg#ArbFONTSGESSTextBold_26") format("svg");
}

body {
  font-family: "GESSTextMedium" !important;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "ArbFONTSGESSTextBold_26" !important;
  }

  p,
  span,
  small,
  div,
  button {
    font-family: "GESSTextMedium" !important;
  }

  .sidebar-container {
    .sidebar-toggle .p-button {
      border-radius: 12px 0px 0px 12px !important;
    }

    .tooltip-container {
      .tooltip {
        border-radius: 12px 0px 0px 12px !important;
      }
    }
  }

  .statistics-container {
    .p-dropdown .p-dropdown-panel {
      position: absolute;
      left: 0px;

      .p-dropdown-header .p-dropdown-filter {
        margin: 0;
      }
    }
  }
}


.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup>.p-inputwrapper:first-child>.p-component,
.p-inputgroup>.p-inputwrapper:first-child>.p-component>.p-inputtext {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

[dir="ltr"] {
  font-family: "GESSTextMedium";
}

// prevent other fonts to effect primeNG icons

.pi {
  font-family: "PrimeIcons" !important;
  /* Ensure PrimeIcons font is applied */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Prevent unexpected spacing */
}

.p-paginator {
  direction: ltr;
}

//  arrow pagination direction
.pagination-section {
  direction: rtl;
  display: flex;
  justify-content: end !important;
  flex-direction: row-reverse;
}

span.p-element.p-dropdown-label.p-inputtext.ng-star-inserted {
  padding: 15px;
}

.p-dropdown .p-dropdown-clear-icon {
  position: relative !important;
  left: 0rem !important;
  right: 0rem !important;
}

.export-icons {
  left: 45px !important;
  right: unset !important;
}
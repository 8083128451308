 .p-paginator {
     background: none !important;
 }

 .p-paginator{
    .p-paginator-pages .p-paginator-page.p-highlight{
        color: var(--primary-color);
        background-color: var(--primary-background-color);
    }

    .p-paginator-pages .p-paginator-page {
        color: var(--secondary-color);
    }

    .p-dropdown{
        border: 1px solid var(--table-border-color);
        border-radius: 12px;
        background-color: var(--primary-background-color);
        padding: 10px;
        height: 45px;
        display: flex;
        align-items: center;

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: var(--primary-color);
        background: var(--secondary-background-color) !important;
    }

    span.p-element.p-dropdown-label.p-inputtext{
        color:var(--fourth-color)
    }
    }

    .p-dropdown:not(.p-disabled).p-focus{
      outline: 1px solid var(--primary-background-color);
    }
} 
// Base font sizes

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

// Font sizes for different screen sizes
$font-sizes: (
  xxs: (
    h1: 18px,
    h2: 17px,
    h3: 16px,
    h4: 15px,
    h5: 14px,
    p:  13px,
    span: 12px,
    small: 10px,
    button: 12px,
  ),
  xs: (
    h1: 20px,
    h2: 18px,
    h3: 16px,
    h4: 14px,
    h5: 12px,
    p: 12px,
    span: 12px,
    small: 8px,
    button: 13px,
  ),
  sm: (
    h1: 24px,
    h2: 20px,
    h3: 18px,
    h4: 16px,
    h5: 14px,
    p: 14px,
    span: 14px,
    small: 10px,
    button: 14px, 
  ),
  md: (
    h1: 28px,
    h2: 24px,
    h3: 20px,
    h4: 18px,
    h5: 16px,
    p: 14px,
    span: 12px,
    small: 10px,
    button: 15px,
  ),
  lg: (
    h1: 32px,
    h2: 28px,
    h3: 24px,
    h4: 20px,
    h5: 18px,
    p: 16px,
    span: 14px,
    small: 12px,
    button: 16px,
  ),
  xl: (
    h1: 36px,
    h2: 32px,
    h3: 28px,
    h4: 24px,
    h5: 20px,
    p: 16px,
    span: 14px,
    small: 13px,
    button: 18px, 
  )
);

// Mixin to apply font sizes based on screen size
@mixin apply-font-sizes($screen) {
  h1 { font-size: map-get(map-get($font-sizes, $screen), h1); }
  h2 { font-size: map-get(map-get($font-sizes, $screen), h2); }
  h3 { font-size: map-get(map-get($font-sizes, $screen), h3); }
  h4 { font-size: map-get(map-get($font-sizes, $screen), h4); }
  h5 { font-size: map-get(map-get($font-sizes, $screen), h5); }
  p  { font-size: map-get(map-get($font-sizes, $screen), p); }
  span { font-size: map-get(map-get($font-sizes, $screen), span); }
  small  { font-size: map-get(map-get($font-sizes, $screen), small); }
  p-button, 
  .p-button, 
  .cancel-btn, 
  .save-btn {
    font-size: map-get(map-get($font-sizes, $screen), button);
  }
}

// Media queries for different screen sizes
@media (max-width: 400px)  { @include apply-font-sizes(xxs); }
@media (min-width: 576px)  { @include apply-font-sizes(xs); }
@media (min-width: 768px)  { @include apply-font-sizes(sm); }
@media (min-width: 992px)  { @include apply-font-sizes(md); }
@media (min-width: 1200px) { @include apply-font-sizes(lg); }
@media (min-width: 1400px) { @include apply-font-sizes(xl); }

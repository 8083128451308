@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot");
  src: url("/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot") format("embedded-opentype"),
       url("/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff2") format("woff2"),
       url("/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff") format("woff"),
       url("/assets/fonts/sfprodisplayregular/SFProDisplayRegular.ttf") format("truetype"),
       url("/assets/fonts/sfprodisplayregular/SFProDisplayRegular.svg#SFProDisplayRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/assets/fonts/sfprodisplaybold/SFProDisplayBold.eot");
  src: url("/assets/fonts/sfprodisplaybold/SFProDisplayBold.eot") format("embedded-opentype"),
       url("/assets/fonts/sfprodisplaybold/SFProDisplayBold.woff2") format("woff2"),
       url("/assets/fonts/sfprodisplaybold/SFProDisplayBold.woff") format("woff"),
       url("/assets/fonts/sfprodisplaybold/SFProDisplayBold.ttf") format("truetype"),
       url("/assets/fonts/sfprodisplaybold/SFProDisplayBold.svg#SFProDisplayBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: "SF Pro Display" !important;
}

h1, h2, h3, h4, h5 {
  font-family: "SF Pro Display";
  font-weight: 600;
}

p, span, small, div, button {
  font-family: "SF Pro Display";
  font-weight: 400;
}

.statistics-container {
  .p-dropdown .p-dropdown-panel {
    position: absolute;
    right: 0px;

    .p-dropdown-header .p-dropdown-filter {
      margin: 0;
    }
  }
}

[dir="ltr"] {
  font-family: "SF Pro Display";
}

// prevent other fonts to effect primeNG icons

.pi {
  font-family: "PrimeIcons" !important; /* Ensure PrimeIcons font is applied */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1; /* Prevent unexpected spacing */
}
//  themes colors

:root {
    // light theme colors
    --light-primary-text-color: #0D415F;
    --light-secondary-text-color: #747474;
    --light-third-text-color: #11744F;
    --light-fourth-text-color: #221F1F;
    --light-fifth-text-color: #90DEC1;
    --light-primary-background-color: #FFFFFF;
    --light-secondary-background-color: #F1F3F3;
    --light-sidebar-bg-hover : #F1F3F3;
    --light-sidebar-dropdown-menu-color : #000;
    --light-bg-img-small-sidebar : #F1F3F3;
    --light-average-background-color: #D8FFDF;
    --light-border-table-color:#DBE0E6;
    --light-table-header-text-color : #969696;
    --light-background-table-hover-color: #D8FFDF;
    --light-loss-case-text-color : #BA1515;
    --light-loss-case-background-color: #F5E0E0;
    --light-paginator-arrow-color: #747474;
    --light-disabled-paginator-arrow-color: #CFCDCD;
    --light-sun-image-color: #F8AE4E;
    --light-cloud-image-color: #D7EBEB;
    --light-step-active-lines-color: #116B6B;
    --light-step-li-background: #E4FAFA;
    --light-inactive-lines-color: #DBE0E6;
    --light-input-background-color: #DBE0E6;
    --light-table-content-color: #4B5563;
    --light-done-status-background: #DEF7FF;
    --light-done-status-text-color: #0D425F;
    --light-pending-status-background: #F5EAE0;
    --light-pending-status-text-color: #D57D0B;
    --light-stamp-minor-title: #969696;
    --light-gift-image-background:#EAEAEA;
    --light-block-status-background : #F1F3F3;
    --light-dinein-bg: #f0f8ff;
    --light-dinein-text: #003366;
    --light-takeaway-bg: #fff4e0;
    --light-takeaway-text: #a0522d;
    --light-pickup-bg: #e6f7e6;
    --light-pickup-text: #006400;
    --light-delivery-bg: #fdf5e6;
    --light-delivery-text: #8b4513;
    --light-drivethru-bg: #ffe4e1;
    --light-drivethru-text: #b22222;
    --light-promising-bg: #F5EAE0;
    --light-promising-text:#D08311;
    --light-disable-notification-bg: #aaaaaa;
  }
  
  
    body.light-mode {
      --primary-color: var(--light-primary-text-color);
      --secondary-color: var(--light-secondary-text-color);
      --third-color: var(--light-third-text-color);
      --fourth-color: var(--light-fourth-text-color);
      --fifth-color: var(--light-fifth-text-color);
      --primary-background-color: var(--light-primary-background-color);
      --secondary-background-color: var(--light-secondary-background-color);
      --sidebar-bg-hover: var(--light-sidebar-bg-hover);
      --sidebar-dropdown-color : var(--light-sidebar-dropdown-menu-color);
      --bg-img-small-sidebar-hover : var(--light-bg-img-small-sidebar);
      --average-background-color: var(--light-average-background-color);
      --table-border-color: var(--light-border-table-color);
      --table-header-color : var(--light-table-header-text-color);
      --table-bg-hover : var(--light-background-table-hover-color);
      --color-loss-case : var(--light-loss-case-text-color);
      --background-loss-case : var(--light-loss-case-background-color);
      --paginator-arrow-color: var(--light-paginator-arrow-color);
      --disabled-color: var(--light-disabled-paginator-arrow-color);
      --sun-image-color: var(--light-sun-image-color);
      --cloud-image-color: var(--light-cloud-image-color);
      --step-active-lines-color: var(--light-step-active-lines-color);
      --step-li-background: var(--light-step-li-background);
      --inactive-lines-color: var(--light-inactive-lines-color);
      --input-background: var(--light-input-background-color);
      --table-content-color: var(--light-table-content-color);
      --done-status-background: var(--light-done-status-background);
      --done-status-text-color: var(--light-done-status-text-color);
      --pending-status-background: var(--light-pending-status-background);
      --pending-status-text-color: var(--light-pending-status-text-color);
      --stamp-minor-title: var(--light-stamp-minor-title);
      --gift-image-background: var(--light-gift-image-background);
      --block-status-background: var(--light-block-status-background);
      --dinein-bg: var(--light-dinein-bg);
      --dinein-text: var(--light-dinein-text);
      --takeaway-bg: var(--light-takeaway-bg);
      --takeaway-text: var(--light-takeaway-text);
      --pickup-bg: var(--light-pickup-bg);
      --pickup-text: var(--light-pickup-text);
      --delivery-bg: var(--light-delivery-bg);
      --delivery-text: var(--light-delivery-text);
      --drivethru-bg: var(--light-drivethru-bg);
      --drivethru-text: var(--light-drivethru-text);
      --promising-bg: var(--light-promising-bg);
      --promising-text: var(--light-promising-text);
      --disable-notification-bg: var(--light-disable-notification-bg);
    }

  .light-mode{
    .nps-container {
      box-shadow: 
        0px -4px 8px rgba(0, 0, 0, 0.1), 
        0px -2px 4px rgba(0, 0, 0, 0.06);
      }
  }
//  this class "form-card" should be parent  for p-card in form page

.form-card {
  p-card {
    display: flex;
    justify-content: start;
    align-items: center;
    width: auto;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;
  }

  // centering form card on mobile screens
  @media (max-width: 768px) {

    p-card {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    ::ng-deep .p-card .p-card-body {
      padding: 20px 10px;
    }
  }
}

.form-styles {

  // form structure
  .col-fixed {
    padding-top: 10px !important;
  }

  ::ng-deep .p-dropdown,
  input {
    width: 100%;
  }

  .field {
    width: 416px;
  }

  .formgrid {
    max-width: calc(416px * 3);
  }

  @media (max-width: 1559px) {
    .formgrid {
      max-width: calc(416px * 2);
    }
  }

  @media (max-width: 1134px) {
    .field {
      width: 100%;
    }

    .formgrid {
      max-width: 100%;
      margin: auto;
    }
  }

  //  fields style

  label {
    color: var(--secondary-color)
  }

  input[pInputText] {
    width: 100%;
    background-color: var(--secondary-background-color);
    border: none;
    height: 40px;
    color: var(--primary-color);
    border-radius: 12px;
  }

  input[pInputText]::placeholder {
    color: var(--secondary-color)
  }

  ::ng-deep .p-checkbox-label {
    color: var(--secondary-color)
  }

  // style for phone numbers field
  ::ng-deep .p-inputgroup,
  input[pInputText] {
    width: 100%;
    background-color: var(--secondary-background-color);
    color: var(--primary-color);
    border-radius: 12px;
    border: 1px solid var(--secondary-background-color);
  }

  ::ng-deep .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup>.p-inputwrapper:first-child>.p-component,
  .p-inputgroup>.p-inputwrapper:first-child>.p-component>.p-inputtext {
    background-color: var(--secondary-background-color);
    border: 1px solid var(--secondary-background-color);
    color: var(--primary-color);
    border-radius: 12px;
    height: 40px;
  }

  .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button.p-inputnumber-button-down,
  .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button.p-inputnumber-button-up {
    background: var(--primary-background-color);
    border: 1px solid;
  }


  //   dropdown

  .p-dropdown.p-variant-filled {
    background-color: var(--secondary-background-color);
    border: 1px solid var(--secondary-background-color);
    height: 40px;
  }

  span.p-element.p-dropdown-label.p-inputtext.ng-star-inserted {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline: none;
  }

  .p-dropdown .p-dropdown-panel {
    background-color: var(--secondary-background-color);
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: var(--secondary-color);
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--primary-color);
    background-color: transparent;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus,
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background-color: var(--secondary-background-color) !important;
  }

  // p-textarea style from primeNG

  textarea#message {
    background-color: var(--secondary-background-color);
    border: 1px solid var(--secondary-background-color);
    color: var(--primary-color);
  }

  .p-inputtext.p-variant-filled:enabled:focus {
    outline: 1px solid var(--stamp-minor-title) !important;
    background-color: transparent !important;
  }

  input[pInputText]:focus {
    outline: 1px solid var(--stamp-minor-title) !important;
    background-color: transparent !important;
  }


  // p-calendar style

  .p-calendar .p-inputtext {
    background-color: var(--secondary-background-color);
    border: 1px solid var(--secondary-background-color);
    color: var(--primary-color);
  }

  .p-calendar:not(.p-calendar-disabled).p-focus>.p-inputtext {
    outline: 1px solid var(--secondary-background-color);
  }


  .p-calendar .p-datepicker {
    color: var(--primary-color);
    background: var(--secondary-background-color);
    border: 1px solid var(--secondary-background-color);
  }

  .custom-input-with-prefix {
    position: relative;
    background-color: var(--secondary-background-color);

    .phone-prefix {
      position: absolute;
      top: 50%;
      left: 2%;
      right: 2%;
      transform: translate(0%, -50%);
      color: var(--fourth-color);
      width: fit-content;
    }

    input[pInputText]:focus {
      outline: 1px solid var(--stamp-minor-title) !important;
      background-color: transparent !important;
    }

    .phone-field {
      padding: 0px 55px;
    }

    label {
      right: unset;
      left: 50px;
    }

    .rtl-label {
      right: 50px;
      left: unset; // Override for RTL
    }
  }

  // Responsive styling for smaller screens
  @media (max-width: 899px) {

    ::ng-deep .p-inputgroup,
    input[pInputText] {
      width: 100%;
    }
  }
}
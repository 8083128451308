.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td{
    height: 48px;
    padding: 6px 15px;
    align-items: center;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: var(--table-border-color);
    text-align: justify;
    white-space: nowrap;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

html[dir="ltr"] .p-datatable .p-datatable-thead > tr > th,
html[dir="ltr"] .p-datatable .p-datatable-tbody > tr > td {
    font-family: 'SF Pro Display';
}

html[dir="rtl"] .p-datatable .p-datatable-thead > tr > th,
html[dir="rtl"] .p-datatable .p-datatable-tbody > tr > td {
    font-family: 'GESSTextMedium';
}

.no-result-message{
    display: flex;
    justify-content: center;
    color: var(--secondary-color);
    margin-top: 30px;
}
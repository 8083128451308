//  themes colors
:root {
  // dark theme colors
  --dark-primary-text-color: #2E90C6;
  --dark-secondary-text-color: #8E8E93;
  --dark-third-text-color: #0D4A3A;
  --dark-fourth-text-color: #E0E0E0;
  --dark-fifth-text-color: #6AB39A;
  --dark-primary-background-color: #262C3A;
  --dark-secondary-background-color: #0E101D;
  --dark-sidebar-bg-hover: transparent;
  --dark-sidebar-dropdown-menu-color: #2E90C6;
  --dark-bg-img-small-sidebar: #0E101D;
  --dark-average-background-color: #A0C8B2;
  --dark-border-table-color: #32343F;
  --dark-table-header-text-color: #8E8E93;
  --dark-background-table-hover-color: #0E101D;
  --dark-loss-case-text-color: #8A1B1B;
  --dark-loss-case-background-color: #D4A7A7;
  --dark-paginator-arrow-color: #E0E0E0;
  --dark-disabled-paginator-arrow-color: #48484A;
  --dark-sun-image-color: #C79042;
  --dark-cloud-image-color: #A5B9B9;
  --dark-step-active-lines-color: #0D5454;
  --dark-step-li-background: #B3D6D6;
  --dark-inactive-lines-color: #636366;
  --dark-input-background-color: #0E101D;
  --dark-table-content-color: #8E8E93;
  --dark-done-status-background: #DEF7FF;
  --dark-done-status-text-color: #2E90C6;
  --dark-pending-status-background: #D4BFAA;
  --dark-pending-status-text-color: #D57D0B;
  --dark-stamp-minor-title: #636366;
  --dark-gift-image-background: #1C2230;
  --dark-block-status-background : #32343F;
  --dark-dinein-bg: #2b4f60;
  --dark-dinein-text: #a0c4d4;
  --dark-takeaway-bg: #704214;
  --dark-takeaway-text: #d7b899;
  --dark-pickup-bg: #2a5235;
  --dark-pickup-text: #b0d8b0;
  --dark-delivery-bg: #52432a;
  --dark-delivery-text: #dec9a6;
  --dark-drivethru-bg: #603030;
  --dark-drivethru-text: #f5c6c6;
  --dark-promising-bg: #D4BFAA;
  --dark-promising-text:#8A5411;
  --dark-disable-notification-bg: #32343F;
}

body.dark-mode {
  --primary-color: var(--dark-primary-text-color);
  --secondary-color: var(--dark-secondary-text-color);
  --third-color: var(--dark-third-text-color);
  --fourth-color: var(--dark-fourth-text-color);
  --fifth-color: var(--dark-fifth-text-color);
  --primary-background-color: var(--dark-primary-background-color);
  --secondary-background-color: var(--dark-secondary-background-color);
  --sidebar-bg-hover: var(--dark-sidebar-bg-hover);
  --sidebar-dropdown-color: var(--dark-sidebar-dropdown-menu-color);
  --bg-img-small-sidebar-hover: var(--dark-bg-img-small-sidebar);
  --average-background-color: var(--dark-average-background-color);
  --table-border-color: var(--dark-border-table-color);
  --table-header-color: var(--dark-table-header-text-color);
  --table-bg-hover: var(--dark-background-table-hover-color);
  --color-loss-case: var(--dark-loss-case-text-color);
  --background-loss-case: var(--dark-loss-case-background-color);
  --paginator-arrow-color: var(--dark-paginator-arrow-color);
  --disabled-color: var(--dark-disabled-paginator-arrow-color);
  --sun-image-color: var(--dark-sun-image-color);
  --could-image-color: var(--dark-cloud-image-color);
  --step-active-lines-color: var(--dark-step-active-lines-color);
  --step-li-background: var(--dark-step-li-background);
  --inactive-lines-color: var(--dark-inactive-lines-color);
  --input-background: var(--dark-input-background-color);
  --table-content-color: var(--dark-table-content-color);
  --done-status-background: var(--dark-done-status-background);
  --done-status-text-color: var(--dark-done-status-text-color);
  --pending-status-background: var(--dark-pending-status-background);
  --pending-status-text-color: var(--dark-pending-status-text-color);
  --stamp-minor-title: var(--dark-stamp-minor-title);
  --gift-image-background: var(--dark-gift-image-background);
  --block-status-background: var(--dark-block-status-background);
  --dinein-bg: var(--dark-dinein-bg);
  --dinein-text: var(--dark-dinein-text);
  --takeaway-bg: var(--dark-takeaway-bg);
  --takeaway-text: var(--dark-takeaway-text);
  --pickup-bg: var(--dark-pickup-bg);
  --pickup-text: var(--dark-pickup-text);
  --delivery-bg: var(--dark-delivery-bg);
  --delivery-text: var(--dark-delivery-text);
  --drivethru-bg: var(--dark-drivethru-bg);
  --drivethru-text: var(--dark-drivethru-text);
  --promising-bg: var(--dark-promising-bg);
  --promising-text: var(--dark-promising-text);
  --disable-notification-bg: var(--dark-disable-notification-bg);
}

.dark-mode {
  .p-dropdown-panel {
    background-color: var(--secondary-background-color);
    .p-dropdown-header {
      background-color: transparent;

      // style for input filter inside dropdown modal
      .p-dropdown-filter {
        color: var(--primary-color);
        margin-right: 0px;
        background-color: var(--primary-background-color);
        outline: none;
        border: none;
        border-radius: 12px;
      }
    }

    .p-dropdown-items {
      .p-dropdown-item {
        font-weight: 500;
        color: var(--secondary-color);

        // Reset background for focus and highlight
        &.p-highlight {
          background-color: var(--secondary-background-color) !important;
        }

        &.p-highlight.p-focus,
        &:not(.p-highlight):not(.p-disabled).p-focus {
          background-color: transparent !important;
        }

        // Hover and focus states
        &.p-highlight.p-focus:hover,
        &:not(.p-highlight):not(.p-disabled):hover {
          background-color: var(--sidebar-bg-hover) !important;
          color: var(--sidebar-dropdown-color);
        }
      }

      // Empty message styling
      .p-dropdown-empty-message {
        color: var(--primary-color);
      }
    }
  }
  .nps-container {
    box-shadow: 
      0px -4px 8px rgba(255, 255, 255, 0.1), 
      0px -2px 4px rgba(255, 255, 255, 0.06);
  }
}
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

// components
@import "./scss/components/card";
@import "./scss//components/table";
@import "./scss/components/dropdown";
@import "./scss/components/progress-bar";
@import "./scss/components/paginator";
@import "./scss/layout/giftcard-carousel.scss";
@import "./scss/components/form";
@import "./scss/components/menu";


// themes imports
@import "./scss/themes/dark";
@import "./scss/themes/light";

//  fonts
@import "./scss/base/font-sizes";



//  general style

html[dir="ltr"]{
  font-family: 'SF Pro Display';
}

html[dir="rtl"] {
  font-family: 'GESSTextMedium';
}

body {
  background-color: var(--secondary-background-color);
  @media (max-width: 768px) {
    padding:0px;
    margin: 0px;
  }
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px;
  padding: 0px;
}


.rtl {
  @import "./scss/styles-ar";
}

.ltr {
  @import "./scss/styles-en";
}

.rtl-label {
  right: 10px;
  left: unset; // Override for RTL
}

.p-toast-top-right {
  top: 105px !important;
}
.p-dropdown-panel {
  border:none;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter{
  margin: 0px !important;
}

.p-button .p-button-icon-left {
  margin: 0px 5px;
}

@media (max-width: 768px) {
  .p-toast {
    width: 100%;
    left: 0%;
    right: 0%;
    top: 25% !important;
    transform: translate(0%, -50%);
    position: fixed;
    z-index: 10000;
  }

  .p-toast-message {
    text-align: center;
  }
}

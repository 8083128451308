.p-card{
    background-color: var(--primary-background-color);
    border-radius: 20px;
    // height: 100%;
    position: relative;

    .card-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 8px 0px 24px;

        .card-title{
            color: var(--secondary-color);
        }
    }

    .second-title{
        color: var(--fourth-color);
    //    font-size: 22px;
        font-weight: 400;
        padding-bottom: 14px;
    }

    .p-card .p-card-body {
        padding: 16px;
    }

    .no-result-message{
        display: flex;
        justify-content: center;
        color: var(--secondary-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        margin-top: 0px;
    }
}

.p-card .p-card-body{
    padding: 1rem !important;
}